// 获取URL的值
export function getUrlParam(name) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || [undefined, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}
export function moveToErr() {
  let isError = document.getElementsByClassName("is-error");
  if (isError.length) {
    isError[0].scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
    // 这个当滑动到报错项之后自动获取输入框的焦点，方便用户直接进行输入，延迟 800ms 是因为需要都能到定位成功后在进行获取焦点体验更好一些
    setTimeout(() => {
      if (isError[0].previousElementSibling !== null) {
        if (isError[0].previousElementSibling.querySelector("input")) {
          isError[0].previousElementSibling.querySelector("input").focus();
        }
      } else {
        if (isError[0].querySelector("input")) {
          isError[0].querySelector("input").focus();
        }
      }
    }, 800);
  }
}